import { Fund } from '../models/Fund'
import { ImageSourcePropType } from 'react-native'

export const ASSET_CLASS_ID_CASH = 'C'
export const ASSET_CLASS_ID_STOCKS = 'A'
export const ASSET_CLASS_ID_FELLOW_FINANCE = 'E'
export const ASSET_CLASS_ID_BOND = 'B'
export const ASSET_CLASS_ID_OTHER = 'D'
export const ASSET_CLASS_ID_IMPACT = 'I'

export const ASSET_CLASS_NAME_IMPACT = 'Impakti'

export const ASSET_CATEGORY_ID_STRUCTURED = 'B05'
export const ASSET_CATEGORY_ID_PRIVATE_EQUITY = 'D01'
export const CREDIT_RISK_LOAN = 'Luottoriskilaina'
export const CREDIT_RISK_LOAN_SE = 'Kreditrisklån'

export const MONEY = 'MONEY'

const SECURITY_TYPE_ID_PRIVATE_EQUITY = '40'

export function isPrivateEquity(securityTypeId?: string): boolean {
  return SECURITY_TYPE_ID_PRIVATE_EQUITY === securityTypeId
}

export function isMoney(ticker: string) {
  return ticker === 'MONEY'
}

export const AssetClassOrder = [
  ASSET_CLASS_ID_BOND,
  ASSET_CLASS_ID_OTHER,
  ASSET_CLASS_ID_STOCKS,
  ASSET_CLASS_ID_FELLOW_FINANCE,
  ASSET_CLASS_ID_CASH,
  ASSET_CLASS_ID_IMPACT,
]

export function isCashCategory(category: string) {
  return category === 'C02'
}

const assetClass: {
  [key: string]: {
    label: string
    image: string
    imageWide: string
    imageImpact: string
  }
} = {
  A: {
    label: 'shares',
    image: require('../../../../../packages/app/assets/products/stocks.jpg'),
    imageWide: require('./osakkeet.jpg'),
    imageImpact: require('../../../../../packages/app/assets/products/impact-stocks.jpg'),
  },
  B: {
    label: 'interest',
    image: require('../../../../../packages/app/assets/products/bonds.jpg'),
    imageWide: require('./korot.jpg'),
    imageImpact: require('../../../../../packages/app/assets/products/impact-bonds.jpg'),
  },
  [ASSET_CLASS_ID_CASH]: {
    label: 'cash',
    image: require('../../../../../packages/app/assets/products/cash.jpg'),
    imageWide: require('./kateinen.jpg'),
    imageImpact: require('../../../../../packages/app/assets/products/impact-stocks.jpg'),
  },
  D: {
    label: 'other',
    image: require('../../../../../packages/app/assets/products/others.jpg'),
    imageWide: require('./muut.jpg'),
    imageImpact: require('../../../../../packages/app/assets/products/impact-stocks.jpg'),
  },
  E: {
    label: 'fellow-finance',
    image: require('../../../../../packages/app/assets/products/fellowfinance.jpg'),
    imageWide: require('./fellow-finance.jpg'),
    imageImpact: require('../../../../../packages/app/assets/products/impact-stocks.jpg'),
  },
  I: {
    label: 'app.impaktifund.header',
    image: require('../../../../../packages/app/assets/products/impact.jpg'),
    imageWide: require('./impakti.jpg'),
    imageImpact: require('../../../../../packages/app/assets/products/impact-alternative.jpg'),
  },
}

export function getAssetClassImageById(assetClassId: string): string {
  return assetClass[assetClassId] && assetClass[assetClassId].image
    ? assetClass[assetClassId].image
    : assetClass.A.image
}

export function getAssetClassWideImageById(assetClassId: string): string {
  return assetClass[assetClassId] && assetClass[assetClassId].imageWide
    ? assetClass[assetClassId].imageWide
    : assetClass.A.imageWide
}

export function getAssetClassImpactImageById(assetClassId: string): string {
  return assetClass[assetClassId] && assetClass[assetClassId].imageImpact
    ? assetClass[assetClassId].imageImpact
    : assetClass.A.imageImpact
}

export function nameForAssetClassId(assetClassId: string) {
  return assetClass[assetClassId] && assetClass[assetClassId].label
    ? assetClass[assetClassId].label
    : ''
}

export function getFundImageSource(fund: Fund): ImageSourcePropType {
  return fund.imageUrl
    ? { uri: fund.imageUrl }
    : (getAssetClassImageById(fund.assetClassId) as unknown as number)
}
