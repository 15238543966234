import { isWeb } from '@taaleri/components/src/constants/Platforms'
import { ROUTE_SIGN_IN_TUPAS_RETURN } from '@taaleri/components/src/navigation/routes'
import { ROUTE_PROFILE_CHANGE_PHONE_DONE } from '@taaleri/components/src/screens/profile/ProfileRoutes'
import { QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY } from '@taaleri/components/src/screens/questions/questions'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ConfigStore from '@taaleri/core/src/stores/ConfigStore'
import logger from '@taaleri/core/src/utils/logger'

import { ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER_DONE } from './../../../components/src/screens/profile/ProfileRoutes'
import { toBasicInfoOnboarding } from './contract/toBasicContract'
import { handleErrors } from './handleErrors'
import { getIdentity, getOnboardingProcessIdentity } from './storage'
import { api } from '../api/api'
import { BasicInfoProductFamiliarity } from '../api/normalization/toAnswers'
import { getConfig } from '../config'
import { ProductKnowledgeOption } from '../constants/Options'
import { isLocal } from '../environment'
import { Attachment } from '../models/Attachment'
import { FINLAND } from '../models/Countries'
import {
  BasicInfoOnboarding,
  Customer,
  CustomerBasicInfo,
} from '../models/Customer'
import { EmojiResponse } from '../models/EmojiResponse'
import { Identity } from '../models/Identity'
import { CameraPhoto, IdVerificationData } from '../models/IdentityCard'
import { InvestmentStrategy } from '../models/InvestmentStrategy'
import { CONFLICT, ServiceResponse } from '../models/ServiceResponse'
import {
  InvestmentProposal,
  PreliminaryProposal,
  PortfolioDistributions,
} from '../models/investmentProposal'
import QuestionStore from '../stores/QuestionStore'

const TUPAS_TEST_URL = 'https://test-tupas-epmuemrrab.now.sh/'

async function getCustomer(customerId: number): Promise<Customer | undefined> {
  try {
    const identity: Identity = await getIdentity()
    const customer = await api().customer.getCustomer(identity, customerId)
    return customer
  } catch (e) {
    handleErrors(e, 'getCustomer')
    return undefined
  }
}

async function getTupasCustomer(): Promise<Customer | null> {
  try {
    const identity: Identity = await getIdentity()
    const customer: Customer = await api().customer.getTupasCustomer(identity)
    if (!customer.nationality) {
      customer.nationality = FINLAND.code
    }
    if (!customer.countryOfBirth) {
      customer.countryOfBirth = FINLAND.code
    }
    if (!customer.email && QuestionStore.email) {
      customer.email = QuestionStore.email
    }

    logger.devInfo('customer', customer)

    QuestionStore.customerMaybe = customer

    return customer
  } catch (e) {
    handleErrors(e, 'getTupasCustomer')
    return null
  }
}

async function getCustomerBasicInfo(
  customerId: number
): Promise<CustomerBasicInfo> {
  try {
    const identity: Identity = await getIdentity()
    const basicInfo = await api().customer.getCustomerBasicInfo(
      identity,
      customerId
    )

    return basicInfo
  } catch (e) {
    handleErrors(e, 'getCustomerBasicInfo')
    throw e
  }
}

async function getCustomerForcedKnowledge(
  customerId: number
): Promise<BasicInfoProductFamiliarity> {
  try {
    const identity: Identity = await getIdentity()
    const forcedKnowledge = await api().customer.getCustomerForcedKnowledge(
      identity,
      customerId
    )
    return forcedKnowledge
  } catch (e) {
    handleErrors(e, 'getCustomerForcedKnowledge')
    throw e
  }
}

type TupasType = 'password' | 'onboarding' | 'phone' | 'accountNumber'

async function doTupas(
  url: string,
  tupasType: TupasType,
  queryStringParams?: string
): Promise<string> {
  const config = getConfig()
  if (config.useDevTupas) {
    let webReturnPath = ROUTE_SIGN_IN_TUPAS_RETURN
    if (tupasType === 'onboarding') {
      webReturnPath = 'App/'
    }
    if (tupasType === 'phone') {
      webReturnPath = 'App/' + ROUTE_PROFILE_CHANGE_PHONE_DONE
    }
    if (tupasType === 'accountNumber') {
      webReturnPath = 'App/' + ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER_DONE
    }
    // @ts-ignore-next-line
    // prettier-ignore
    const returnUrl = isWeb ? `${window.location.protocol}//${window.location.host}/${webReturnPath}`
      : isLocal
        ? 'exp://127.0.0.1:19000/--/'
        : 'taaleri://'
    return `${TUPAS_TEST_URL}?returnUrl=${returnUrl}`
  } else {
    const nonProd = config.environment !== 'production'
    const clientType = isWeb ? 'web' : 'mobile'
    const queryString = `clientType=${clientType}&debug=${isLocal}${
      nonProd && ConfigStore.useActualTupasResult ? '&useActualResult=true' : ''
    }`
    return `${url}?${
      queryStringParams ? queryStringParams + '&' : ''
    }${queryString}`
  }
}

async function getTupasUrlPassword(): Promise<string> {
  return doTupas(
    `${getConfig().apiUrl}/Authentication/identification/resetornew`,
    'password'
  )
}

async function getTupasUrl(): Promise<string> {
  const processId = await getOnboardingProcessIdentity()

  const url = `${
    getConfig().apiUrl
  }/Authentication/identification/onboarding/${processId}`

  return doTupas(url, 'onboarding')
}

async function getTupasUrlChangePhone(phone: string): Promise<string> {
  /* 
  Plus sign in query parameter is decoded as space, use %2B instead.
  More information: https://stackoverflow.com/questions/6855624
  */
  const formattedPhone = phone.replace('+', '%2B')
  return doTupas(
    `${getConfig().apiUrl}/User/phonenumber/${AppStore.customerId}`,
    'phone',
    `phoneNumber=${formattedPhone}`
  )
}

async function getTupasUrlChangeAccountNumber(
  accountNumber: string
): Promise<string> {
  // TODO FIX URL
  return doTupas(
    `${getConfig().apiUrl}/User/bankaccount/${AppStore.customerId}`,
    'accountNumber',
    `bankAccountNumber=${accountNumber.replace(/ /g, '').trim()}`
  )
}

async function getInvestmentProposalOnboarding(): Promise<InvestmentProposal> {
  try {
    const basicInfoOnboarding: BasicInfoOnboarding = toBasicInfoOnboarding(
      QuestionStore.answers
    )

    const assetManagementFamiliarity = QuestionStore.getAnswer(
      QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY
    ) as string

    basicInfoOnboarding.knowledgeDiscretionaryAssetManagement =
      assetManagementFamiliarity || ProductKnowledgeOption.DoesNotKnow

    basicInfoOnboarding.knowledgeCapitalInvestments =
      ProductKnowledgeOption.DoesNotKnow
    basicInfoOnboarding.knowledgeCapitalSecuredStructuredProducts =
      ProductKnowledgeOption.DoesNotKnow
    basicInfoOnboarding.knowledgeCombinationFunds =
      ProductKnowledgeOption.DoesNotKnow
    basicInfoOnboarding.knowledgeCommodities =
      ProductKnowledgeOption.DoesNotKnow
    basicInfoOnboarding.knowledgeComplexDebt =
      ProductKnowledgeOption.DoesNotKnow

    basicInfoOnboarding.knowledgeEquities = ProductKnowledgeOption.DoesNotKnow
    basicInfoOnboarding.knowledgeFixedIncome =
      ProductKnowledgeOption.DoesNotKnow
    basicInfoOnboarding.knowledgeLeveragedSecurities =
      ProductKnowledgeOption.DoesNotKnow
    basicInfoOnboarding.knowledgeMoneyMarketFunds =
      ProductKnowledgeOption.DoesNotKnow
    basicInfoOnboarding.knowledgeUnsecuredStructuredProducts =
      ProductKnowledgeOption.DoesNotKnow

    return await api().customer.getInvestmentProposalOnboarding(
      basicInfoOnboarding
    )
  } catch (e) {
    handleErrors(e, 'getInvestmentProposalOnboarding')
    throw e
  }
}

async function getInvestmentProposal(
  customerId: number
): Promise<InvestmentProposal> {
  try {
    const basicInfoOnboarding: BasicInfoOnboarding = toBasicInfoOnboarding(
      QuestionStore.answers
    )
    const identity: Identity = await getIdentity()
    return await api().customer.getInvestmentProposal(
      customerId,
      identity,
      basicInfoOnboarding
    )
  } catch (e) {
    handleErrors(e, 'getInvestmentProposal')
    throw e
  }
}

async function getCorrespondingImpactStrategy(
  customerId: number
): Promise<PortfolioDistributions> {
  try {
    const identity: Identity = await getIdentity()
    return await api().customer.getImpactStrategy(customerId, identity)
  } catch (e) {
    handleErrors(e, 'getImpactStrategy')
    throw e
  }
}

async function getStrategyByRiskLevel(
  isImpact: boolean,
  riskLevel: number
): Promise<ServiceResponse<InvestmentStrategy>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().customer.getStrategyByRiskLevel(
      identity,
      isImpact,
      riskLevel
    )
    return { response, success: true }
  } catch (error) {
    handleErrors(error, 'getStrategyByRiskLevel')
    return { success: false, error }
  }
}

async function getInvestmentProposalSaved(
  customerId: number
): Promise<InvestmentProposal> {
  try {
    const identity: Identity = await getIdentity()
    return await api().customer.getInvestmentProposalSaved(customerId, identity)
  } catch (e) {
    handleErrors(e, 'getInvestmentProposalSaved')
    throw e
  }
}

async function getFirstProposal(): Promise<InvestmentProposal> {
  try {
    const basicInfoOnboarding: BasicInfoOnboarding = toBasicInfoOnboarding(
      QuestionStore.answers
    )
    return await api().customer.getFirstProposal(
      basicInfoOnboarding,
      QuestionStore.age,
      QuestionStore.isImpact
    )
  } catch (e) {
    handleErrors(e, 'getFirstProposal')
    throw e
  }
}

async function getPreliminaryProposal(): Promise<PreliminaryProposal> {
  try {
    const basicInfoOnboarding: BasicInfoOnboarding = toBasicInfoOnboarding(
      QuestionStore.answers
    )
    return await api().customer.getPreliminaryProposal(
      basicInfoOnboarding,
      QuestionStore.age
    )
  } catch (e) {
    handleErrors(e, 'getPreliminaryProposal')
    throw e
  }
}

async function pollContract(): Promise<ServiceResponse<void>> {
  try {
    const identity: Identity = await getIdentity()
    const contractIsAvailable = await api().customer.pollContract(identity)

    if (contractIsAvailable) {
      return { success: true }
    }

    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e, 'pollContract')
    return { success: false, error }
  }
}

async function pollCustomer(
  customerId: number
): Promise<ServiceResponse<Customer>> {
  try {
    const identity: Identity = await getIdentity()
    const customer: Customer | null = await api().customer.pollCustomer(
      identity,
      customerId
    )

    if (customer) {
      return { success: true, response: customer }
    }

    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e, 'pollCustomer')
    return { success: false, error }
  }
}

async function validateIban(iban: string): Promise<ServiceResponse<string>> {
  try {
    const identity = await getIdentity()
    const validation = await api().customer.validateIban(identity, iban)

    return {
      success: validation.isSuccess,
      response: validation.formattedValue,
    }
  } catch (e) {
    const error = handleErrors(e, 'validateIban')
    return { success: false, response: iban, error }
  }
}

async function postCustomerView(
  customerId: number
): Promise<ServiceResponse<boolean>> {
  try {
    const identity = await getIdentity()
    const response = await api().customer.postCustomerView(identity, customerId)

    return {
      success: true,
      response,
    }
  } catch (e) {
    const error = handleErrors(e, 'postCustomerView')
    return { success: false, error }
  }
}

async function updateIdentityCard(
  idData: IdVerificationData,
  customer: Customer,
  photo: CameraPhoto
): Promise<ServiceResponse<void>> {
  try {
    const identity = await getIdentity()
    const customerName = `${customer.firstName} ${customer.lastName}`

    const success = await api().customer.saveIdentityCard(
      identity,
      customer.customerId,
      customerName,
      idData,
      photo.base64
    )

    if (success) {
      return { success: true }
    }

    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e, 'updateIdentityCard')
    return { success: false, error }
  }
}

async function getOnboardingState(): Promise<ServiceResponse<any>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().customer.getOnboardingState(identity)
    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e, 'getOnboardingState')
    return { success: false, error }
  }
}

async function sendFeedback(
  question: string,
  message: string,
  customerId: number,
  skipEmail = false
): Promise<ServiceResponse<any>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().customer.sendFeedback(
      identity,
      question,
      message,
      customerId,
      skipEmail
    )
    return { success: response.value, response }
  } catch (e) {
    const error = handleErrors(e, 'sendFeedback')
    return { success: false, error }
  }
}

async function sendFeedbackWithEmoji(
  question: string,
  message: string,
  customerId: number,
  emojiResponse: EmojiResponse
): Promise<ServiceResponse<any>> {
  return sendFeedback(question, `:${emojiResponse}: ${message}`, customerId)
}

async function sendEmoji(
  emojiResponse: EmojiResponse,
  customerId: number
): Promise<ServiceResponse<any>> {
  return sendFeedback('', `:${emojiResponse}:`, customerId)
}

async function saveLanguage(
  customerId: number,
  language: string
): Promise<boolean> {
  const identity = await getIdentity()
  return await api().customer.saveLastUsedLanguage(
    identity,
    customerId.toString(),
    language
  )
}

async function getCustomerAttachments(
  customerId: number
): Promise<Attachment[]> {
  const identity = await getIdentity()
  return await api().customer.getCustomerAttachments(identity, customerId)
}

export const customers = {
  getCustomer,
  getTupasCustomer,
  getTupasUrl,
  getTupasUrlPassword,
  getTupasUrlChangePhone,
  getTupasUrlChangeAccountNumber,
  getCustomerForcedKnowledge,
  getInvestmentProposal,
  getCorrespondingImpactStrategy,
  getInvestmentProposalSaved,
  getInvestmentProposalOnboarding,
  getFirstProposal,
  getPreliminaryProposal,
  pollContract,
  pollCustomer,
  postCustomerView,
  validateIban,
  getCustomerBasicInfo,
  updateIdentityCard,
  getOnboardingState,
  sendFeedback,
  sendFeedbackWithEmoji,
  sendEmoji,
  getStrategyByRiskLevel,
  saveLanguage,
  getCustomerAttachments,
}
